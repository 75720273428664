<template>
  <v-container class="fill-height"
               fluid>
    <v-row align="center"
           justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <v-img :src="!isMobile ? '/img/animations/404.gif' : '/img/animations/404_mobile.gif'"
                 :width="!isMobile ? '800px' : '280px'"
                 :min-width="!isMobile ? '800px' : '280px'"
                 :max-width="!isMobile ? '800px' : '280px'" />
        </v-row>

        <div class="text-h3 text-center font-weight-thin sb-darkgrey--text">
          Page not found
        </div>

        <v-row justify="center">
          <v-col cols="12"
                 sm="8"
                 md="6"
                 class="text-body-1 text-center ma-5 pa-5">
            We’re sorry but we can’t find the page you’re looking for, maybe it’s been moved,
            changed or removed. Please check the address again or try one of the options below
            to help you find what you are looking for:
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      user: {
        username: '',
        password: '',
      },
    };
  },
};
</script>
